import { Injectable } from '@angular/core';
import { Attachment } from '@dougs/core/files';
import { SynchronizedAccount } from '@dougs/synchronized-accounts/dto';

@Injectable({
  providedIn: 'root',
})
export class AttachmentService {
  addAttachmentSynchronizedAccountFromKey(
    synchronizedAccount: SynchronizedAccount,
    attachment: Attachment,
    modelFileKey: keyof SynchronizedAccount,
  ): SynchronizedAccount {
    return {
      ...synchronizedAccount,
      [modelFileKey]: [...(synchronizedAccount[modelFileKey] as Attachment[]), attachment],
    };
  }

  removeAttachmentSynchronizedAccountFromKey(
    synchronizedAccount: SynchronizedAccount,
    attachment: Attachment,
    modelFileKey: keyof SynchronizedAccount,
  ): SynchronizedAccount {
    return {
      ...synchronizedAccount,
      [modelFileKey]: (synchronizedAccount[modelFileKey] as Attachment[]).filter(
        (attachmentIterated) => attachmentIterated.id !== attachment.id,
      ),
    };
  }

  updateAttachmentSynchronizedAccountFromKey(
    synchronizedAccount: SynchronizedAccount,
    attachment: Attachment,
    modelFileKey: keyof SynchronizedAccount,
  ): SynchronizedAccount {
    return {
      ...synchronizedAccount,
      [modelFileKey]: (synchronizedAccount[modelFileKey] as Attachment[]).map((attachmentIterated) =>
        attachmentIterated.id !== attachment.id ? attachmentIterated : attachment,
      ),
    };
  }
}
