import { Injectable } from '@angular/core';
import { AccountingSurvey } from '@dougs/accounting-survey/dto';
import { Attachment } from '@dougs/core/files';
import { SynchronizedAccount } from '@dougs/synchronized-accounts/dto';
import { SynchronizedAccountStateService } from '../states/synchronized-account.state';

@Injectable({
  providedIn: 'root',
})
export class BankStatementService {
  constructor(private readonly synchronizedAccountStateService: SynchronizedAccountStateService) {}
  async attachBankStatement(
    account: SynchronizedAccount,
    survey: AccountingSurvey,
    file: File,
  ): Promise<Attachment | null> {
    return await this.synchronizedAccountStateService.uploadAttachment(account, file, {
      type: 'bankStatements',
      metadata: {
        bankStatementId: this.getClosingDateForBankStatement(account, survey) || '',
      },
      fileType: 'bankStatement',
    });
  }

  getClosingDateForBankStatement(account: SynchronizedAccount, survey: AccountingSurvey): string | undefined {
    return survey.data.bankStatement.data?.items?.find((item) => item.accountId === account.id)?.closingPeriod;
  }
}
