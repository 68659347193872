import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppConfig } from '@dougs/core/config';
import { Attachment } from '@dougs/core/files';
import { AllSynchronizedAccountFileAttachmentOptions, SynchronizedAccount } from '@dougs/synchronized-accounts/dto';

@Injectable({
  providedIn: 'root',
})
export class FileHttpService {
  constructor(private readonly http: HttpClient) {}

  uploadCSV(file: File): Observable<Attachment> {
    const formData: FormData = new FormData();
    formData.append('file', file);
    return this.http.post<Attachment>(`${AppConfig.settings.legacyApiServerUrl}/files?filename=${file.name}`, formData);
  }

  deleteSynchronizedAccountFile(account: SynchronizedAccount, attachment: Attachment): Observable<void> {
    return this.http.delete<void>(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${account.companyId}/accounts/${account.id}/attachments/${attachment.id}`,
    );
  }

  updateSynchronizedAccountFile(attachment: Attachment): Observable<Attachment> {
    return this.http.post<Attachment>(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${attachment.companyId}/accounts/${attachment.modelId}/attachments/${attachment.id}`,
      attachment,
    );
  }

  uploadSynchronizedAccountFile(
    account: SynchronizedAccount,
    file: File,
    options: AllSynchronizedAccountFileAttachmentOptions,
  ): Observable<Attachment> {
    const formData: FormData = new FormData();
    let httpParams: HttpParams = new HttpParams();

    formData.append('file', file);

    if (options.metadata) {
      httpParams = httpParams.append('metadata', JSON.stringify(options.metadata));
    }

    return this.http.post<Attachment>(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${account.companyId}/accounts/${account.id}/attachments?filename=${file.name}&type=${options.fileType}`,
      formData,
      { params: httpParams },
    );
  }
}
