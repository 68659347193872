import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppConfig } from '@dougs/core/config';
import { JobDto } from '@dougs/core/job-dto';
import {
  SynchronizedAccount,
  SynchronizedAccountPost,
  TransactionProvider,
  TransactionProviderMergePost,
} from '@dougs/synchronized-accounts/dto';

@Injectable({
  providedIn: 'root',
})
export class SynchronizedAccountHttpService {
  constructor(private readonly http: HttpClient) {}

  getAccounts(companyId: number): Observable<SynchronizedAccount[]> {
    return this.http.get<SynchronizedAccount[]>(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${companyId}/accounts`,
      {
        params: new HttpParams().append('all', true),
      },
    );
  }

  getAccount(synchronizedAccount: SynchronizedAccount): Observable<SynchronizedAccount> {
    return this.http.get<SynchronizedAccount>(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${synchronizedAccount.companyId}/accounts/${synchronizedAccount.id}`,
    );
  }

  createAccount(companyId: number, synchronizedAccount: SynchronizedAccountPost): Observable<SynchronizedAccount> {
    return this.http.post<SynchronizedAccount>(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${companyId}/accounts`,
      synchronizedAccount,
    );
  }

  updateAccount(
    account: SynchronizedAccount,
    params?: {
      updateReconciliationExpectedBalances?: boolean;
      updateReconciliation?: boolean;
    },
  ): Observable<SynchronizedAccount> {
    let httpParams: HttpParams = new HttpParams();

    if (params?.updateReconciliationExpectedBalances) {
      httpParams = httpParams.append(
        'updateReconciliationExpectedBalances',
        params.updateReconciliationExpectedBalances,
      );
    }

    if (params?.updateReconciliation) {
      httpParams = httpParams.append('updateReconciliation', params.updateReconciliation);
    }

    return this.http.post<SynchronizedAccount>(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${account.companyId}/accounts/${account.id}`,
      account,
      {
        params: httpParams,
      },
    );
  }

  deleteAccount(account: SynchronizedAccount): Observable<void> {
    return this.http.delete<void>(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${account.companyId}/accounts/${account.id}`,
    );
  }

  deleteProvider(provider: TransactionProvider): Observable<void> {
    return this.http.delete<void>(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${provider.companyId}/accounts/${provider.accountId}/providers/${provider.id}`,
    );
  }

  updateProvider(provider: TransactionProvider): Observable<TransactionProvider> {
    return this.http.post<TransactionProvider>(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${provider.companyId}/accounts/${provider.accountId}/providers/${provider.id}`,
      provider,
    );
  }

  getProvider(provider: TransactionProvider): Observable<TransactionProvider> {
    return this.http.get<TransactionProvider>(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${provider.companyId}/accounts/${provider.accountId}/providers/${provider.id}`,
    );
  }

  enableProvider(provider: TransactionProvider): Observable<TransactionProvider> {
    return this.http.post<TransactionProvider>(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${provider.companyId}/accounts/${provider.accountId}/providers/${provider.id}/actions/enable`,
      {},
    );
  }

  synchronizeProvider(provider: TransactionProvider): Observable<JobDto> {
    return this.http.post<JobDto>(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${provider.companyId}/accounts/${provider.accountId}/providers/${provider.id}/actions/synchronize-transactions`,
      {},
    );
  }

  moveProvider(provider: TransactionProvider, targetAccountId: number): Observable<TransactionProvider> {
    return this.http.post<TransactionProvider>(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${provider.companyId}/accounts/${provider.accountId}/providers/${provider.id}/actions/move`,
      {
        targetAccountId,
      },
    );
  }

  getAvailableColors(account: SynchronizedAccount): Observable<string[]> {
    return this.http.get<string[]>(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${account.companyId}/accounts/${account.id}/available-colors`,
    );
  }

  mergeProvider(
    synchronizedAccount: SynchronizedAccount,
    providerToMerge: TransactionProvider,
    provider: TransactionProviderMergePost,
  ): Observable<TransactionProvider> {
    return this.http.post<TransactionProvider>(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${synchronizedAccount.companyId}/accounts/${synchronizedAccount.id}/providers/${providerToMerge.id}/actions/merge`,
      provider,
    );
  }
}
