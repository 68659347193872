import { Injectable } from '@angular/core';
import { lastValueFrom, Observable } from 'rxjs';
import { Company } from '@dougs/company/dto';
import { Attachment } from '@dougs/core/files';
import { JobApiService } from '@dougs/core/job';
import { JobDto } from '@dougs/core/job-dto';
import { LoggerService } from '@dougs/core/logger';
import { StateService } from '@dougs/core/state';
import {
  AllSynchronizedAccountFileAttachmentOptions,
  ConnectionAccount,
  SynchronizedAccount,
  SynchronizedAccountPost,
  TransactionProvider,
  TransactionProviderDigestStatusCode,
} from '@dougs/synchronized-accounts/dto';
import { FileHttpService } from '../http/file.http';
import { SynchronizedAccountHttpService } from '../http/synchronized-account.http';
import { AttachmentService } from '../services/attachment.service';

interface SynchronizedAccountState {
  synchronizedAccounts: SynchronizedAccount[];
}

@Injectable({
  providedIn: 'root',
})
export class SynchronizedAccountStateService extends StateService<SynchronizedAccountState> {
  readonly synchronizedAccounts$: Observable<SynchronizedAccount[]> = this.select(
    (state) => state.synchronizedAccounts?.filter((acc) => !acc.hidden) ?? [],
  );
  readonly allSynchronizedAccounts$: Observable<SynchronizedAccount[]> = this.select(
    (state) => state.synchronizedAccounts,
  );
  readonly synchronizingAccounts$: Observable<SynchronizedAccount[]> = this.select((state) =>
    state.synchronizedAccounts.filter((synchronizedAccount) =>
      synchronizedAccount.providers.some(
        (provider) =>
          provider.transactionsDigest?.status?.code === TransactionProviderDigestStatusCode.CREATING_OPERATIONS ||
          provider.transactionsDigest?.status?.code === TransactionProviderDigestStatusCode.FETCHING_TRANSACTIONS,
      ),
    ),
  );

  constructor(
    private readonly synchronizedAccountHttpService: SynchronizedAccountHttpService,
    private readonly attachmentService: AttachmentService,
    private readonly fileHttpService: FileHttpService,
    private readonly jobApiService: JobApiService,
    private readonly logger: LoggerService,
  ) {
    super();
  }

  async refreshSynchronizedAccounts(companyId: number, force?: boolean): Promise<void> {
    try {
      const allAccounts: SynchronizedAccount[] =
        this.getAndSetCacheState('synchronized-accounts', companyId) && !force
          ? this.state.synchronizedAccounts
          : await lastValueFrom(this.synchronizedAccountHttpService.getAccounts(companyId));

      this.setState({
        synchronizedAccounts: this.sortSynchronizedAccounts(allAccounts),
      });
    } catch (e) {
      this.clearCache('synchronized-accounts');
      this.logger.error(e);
    }
  }

  async refreshSynchronizedAccount(synchronizedAccount: SynchronizedAccount): Promise<void> {
    try {
      const accountCreated: SynchronizedAccount = await lastValueFrom(
        this.synchronizedAccountHttpService.getAccount(synchronizedAccount),
      );

      this.setAccountState(accountCreated);
    } catch (e) {
      this.logger.error(e);
    }
  }

  async updateAccountMemo(account: SynchronizedAccount, memo: string): Promise<void> {
    try {
      const updatedAccount: SynchronizedAccount = await lastValueFrom(
        this.synchronizedAccountHttpService.updateAccount({
          ...account,
          memo,
        }),
      );

      this.setAccountState(updatedAccount);
    } catch (e) {
      this.logger.error(e);
    }
  }

  async uploadAttachment(
    account: SynchronizedAccount,
    file: File,
    options: AllSynchronizedAccountFileAttachmentOptions,
  ): Promise<Attachment | null> {
    try {
      const attachment: Attachment = await lastValueFrom(
        this.fileHttpService.uploadSynchronizedAccountFile(account, file, options),
      );

      account = this.attachmentService.addAttachmentSynchronizedAccountFromKey(account, attachment, options.type);
      this.setAccountState(account);

      return attachment;
    } catch (e) {
      this.logger.error(e);

      return null;
    }
  }

  async updateAttachment(
    account: SynchronizedAccount,
    file: Attachment,
    type: keyof SynchronizedAccount,
  ): Promise<Attachment | null> {
    try {
      const attachment: Attachment = await lastValueFrom(this.fileHttpService.updateSynchronizedAccountFile(file));

      account = this.attachmentService.updateAttachmentSynchronizedAccountFromKey(account, attachment, type);
      this.setAccountState(account);

      return attachment;
    } catch (e) {
      this.logger.error(e);

      return null;
    }
  }

  async removeAttachment(
    account: SynchronizedAccount,
    attachment: Attachment,
    type: keyof SynchronizedAccount,
  ): Promise<void> {
    try {
      await lastValueFrom(this.fileHttpService.deleteSynchronizedAccountFile(account, attachment));

      account = this.attachmentService.removeAttachmentSynchronizedAccountFromKey(account, attachment, type);
      this.setAccountState(account);
    } catch (e) {
      this.logger.error(e);
    }
  }

  async createAccount(
    company: Company,
    synchronizedAccount: SynchronizedAccountPost,
  ): Promise<SynchronizedAccount | null> {
    try {
      const createdSynchronizedAccount: SynchronizedAccount = await lastValueFrom(
        this.synchronizedAccountHttpService.createAccount(company.id, synchronizedAccount),
      );
      this.setState({
        synchronizedAccounts: this.sortSynchronizedAccounts([
          ...this.state.synchronizedAccounts,
          createdSynchronizedAccount,
        ]),
      });

      return createdSynchronizedAccount;
    } catch (e) {
      this.logger.error(e);

      return null;
    }
  }

  async updateAccount(
    account: SynchronizedAccount,
    params?: {
      updateReconciliationExpectedBalances?: boolean;
      updateReconciliation?: boolean;
    },
  ): Promise<void> {
    try {
      const updatedAccount: SynchronizedAccount = await lastValueFrom(
        this.synchronizedAccountHttpService.updateAccount(account, params),
      );

      this.setAccountState(updatedAccount);
    } catch (e) {
      this.logger.error(e);
    }
  }

  async deleteAccount(account: SynchronizedAccount): Promise<void> {
    try {
      await lastValueFrom(this.synchronizedAccountHttpService.deleteAccount(account));
      this.setState({
        synchronizedAccounts: this.state.synchronizedAccounts.filter(
          (accountIterated) => accountIterated.id !== account.id,
        ),
      });
    } catch (e) {
      this.logger.error(e);
    }
  }

  async deleteProvider(provider: TransactionProvider): Promise<void> {
    try {
      await lastValueFrom(this.synchronizedAccountHttpService.deleteProvider(provider));
      this.setState({
        synchronizedAccounts: this.state.synchronizedAccounts.map((accountIterated) =>
          accountIterated.id === provider.accountId
            ? {
                ...accountIterated,
                providers: accountIterated.providers.filter((providerIterated) => providerIterated.id !== provider.id),
              }
            : accountIterated,
        ),
      });
    } catch (e) {
      this.logger.error(e);
    }
  }

  async updateProvider(provider: TransactionProvider): Promise<void> {
    try {
      const providerUpdated: TransactionProvider = await lastValueFrom(
        this.synchronizedAccountHttpService.updateProvider(provider),
      );
      this.setState({
        synchronizedAccounts: this.state.synchronizedAccounts.map((accountIterated) =>
          accountIterated.id === providerUpdated.accountId
            ? {
                ...accountIterated,
                providers: accountIterated.providers.map((providerIterated) =>
                  providerUpdated.id === providerIterated.id ? providerUpdated : providerIterated,
                ),
              }
            : accountIterated,
        ),
      });
    } catch (e) {
      this.logger.error(e);
    }
  }

  async moveProvider(provider: TransactionProvider, targetAccountId: number): Promise<void> {
    try {
      const providerUpdated: TransactionProvider = await lastValueFrom(
        this.synchronizedAccountHttpService.moveProvider(provider, targetAccountId),
      );

      this.setState({
        synchronizedAccounts: this.state.synchronizedAccounts.map((account) => {
          if (account.id === providerUpdated.accountId) {
            return {
              ...account,
              providers: account.providers.filter((providerIterated) => providerIterated.id !== providerUpdated.id),
            };
          }

          if (providerUpdated.accountId === account.id) {
            return {
              ...account,
              providers: [...account.providers, providerUpdated],
            };
          }

          return account;
        }),
      });
    } catch (e) {
      this.logger.error(e);
    }
  }

  async enableProvider(provider: TransactionProvider): Promise<void> {
    try {
      const providerEnabled: TransactionProvider = await lastValueFrom(
        this.synchronizedAccountHttpService.enableProvider(provider),
      );

      this.setState({
        synchronizedAccounts: this.state.synchronizedAccounts.map((account) => ({
          ...account,
          providers: account.providers.map((providerIterated) =>
            providerIterated.id === providerEnabled.id ? providerEnabled : providerIterated,
          ),
        })),
      });
    } catch (e) {
      this.logger.error(e);
    }
  }

  async synchronizeProvider(provider: TransactionProvider): Promise<void> {
    try {
      const job: JobDto = await lastValueFrom(this.synchronizedAccountHttpService.synchronizeProvider(provider));
      await lastValueFrom(this.jobApiService.handleJob(job));
    } catch (e) {
      this.logger.error(e);
    }

    try {
      const providerUpdated: TransactionProvider = await lastValueFrom(
        this.synchronizedAccountHttpService.getProvider(provider),
      );

      this.setState({
        synchronizedAccounts: this.state.synchronizedAccounts.map((account) => ({
          ...account,
          providers: account.providers.map((providerIterated) =>
            providerIterated.id === providerUpdated.id ? providerUpdated : providerIterated,
          ),
        })),
      });
    } catch (e) {
      this.logger.error(e);
    }
  }

  async getAvailableColors(account: SynchronizedAccount): Promise<string[] | null> {
    try {
      return await lastValueFrom(this.synchronizedAccountHttpService.getAvailableColors(account));
    } catch (e) {
      this.logger.error(e);
      return null;
    }
  }

  getSelectedAccountIds(hiddenAccountIds: number[]): number[] {
    const selectedAccountIds: number[] = this.state.synchronizedAccounts
      .filter((synchronizedAccount: SynchronizedAccount) => !hiddenAccountIds.includes(synchronizedAccount.id))
      .map((synchronizedAccount: SynchronizedAccount) => synchronizedAccount.id);

    if (selectedAccountIds.length === this.state.synchronizedAccounts.length) {
      return [];
    }
    return selectedAccountIds;
  }

  getProviderById(providerId: number): TransactionProvider | undefined {
    for (const synchronizedAccount of this.state.synchronizedAccounts) {
      const provider: TransactionProvider | undefined = synchronizedAccount.providers.find(
        (provider) => provider.id === providerId,
      );

      if (provider) {
        return provider;
      }
    }
  }

  async mergeProvider(
    account: ConnectionAccount,
    providerToMerge: TransactionProvider,
    synchronizedAccount: SynchronizedAccount,
  ): Promise<void> {
    try {
      const provider: TransactionProvider = await lastValueFrom(
        this.synchronizedAccountHttpService.mergeProvider(synchronizedAccount, providerToMerge, {
          connectionId: account.connectionId,
          externalId: account.externalId,
        }),
      );

      if (provider.job) {
        await lastValueFrom(this.jobApiService.handleJob(provider.job));
      }
    } catch (e) {
      this.logger.error(e);
    }
  }

  getSynchronizedAccountById(synchronizedAccountId: number): SynchronizedAccount | undefined {
    return this.state?.synchronizedAccounts.find(
      (synchronizedAccount) => synchronizedAccount.id === synchronizedAccountId,
    );
  }

  private setAccountState(account: SynchronizedAccount): void {
    this.setState({
      synchronizedAccounts: this.state.synchronizedAccounts.map((synchronizedAccount) =>
        synchronizedAccount.id === account.id ? account : synchronizedAccount,
      ),
    });
  }

  private sortSynchronizedAccounts(synchronizedAccounts: SynchronizedAccount[]): SynchronizedAccount[] {
    return synchronizedAccounts.sort((a, b) => {
      if (a.closed) {
        return 1;
      }

      if (b.closed) {
        return -1;
      }

      return (
        b.metadata.balance?.incomes - a.metadata.balance?.incomes ||
        b.metadata.balance?.expenses - a.metadata.balance?.expenses ||
        b.metadata.balance?.balance - a.metadata.balance?.balance
      );
    });
  }
}
