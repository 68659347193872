import { Injectable } from '@angular/core';
import { Event, NavigationEnd, Router } from '@angular/router';
import { BehaviorSubject, combineLatest, filter, map, Observable } from 'rxjs';
import { CompanyDemoStateService, CompanyStateService } from '@dougs/company/shared';
import { URL } from '@dougs/core/routing';
import { toPromise } from '@dougs/core/utils';
import { InvestmentStateService } from '@dougs/investment/shared';
import { LoanStateService } from '@dougs/loans/shared';
import {
  NeedsAttentionOperationsStateService,
  NotValidatedOperationsStateService,
  ValidatedOperationsStateService,
} from '@dougs/operations/shared';
import { PartnerStateService } from '@dougs/partners/shared';
import { VehicleStateService } from '@dougs/vehicles/shared';
import { SynchronizedAccountStateService, TreasuryStateService } from '../states';

@Injectable({
  providedIn: 'root',
})
export class DemoModeService {
  constructor(
    private readonly companyStateService: CompanyStateService,
    private readonly companyDemoStateService: CompanyDemoStateService,
    private readonly synchronizedAccountStateService: SynchronizedAccountStateService,
    private readonly notValidatedOperationsStateService: NotValidatedOperationsStateService,
    private readonly validatedOperationsStateService: ValidatedOperationsStateService,
    private readonly needsAttentionOperationsStateService: NeedsAttentionOperationsStateService,
    private readonly treasuryStateService: TreasuryStateService,
    private readonly vehicleStateService: VehicleStateService,
    private readonly loanStateService: LoanStateService,
    private readonly investmentStateService: InvestmentStateService,
    private readonly partnerStateService: PartnerStateService,
    private readonly router: Router,
  ) {}

  private readonly EXCLUDED_PAGES_FROM_DEMO_MODE: URL[] = [URL.VENDOR_INVOICE, URL.SALES_INVOICE, URL.SETTINGS];

  private readonly shouldShowDemoModeFooterSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  shouldShowDemoModeFooter$: Observable<boolean> = this.shouldShowDemoModeFooterSubject.asObservable();

  refreshDemoModeFooterDisplay$: Observable<void> = combineLatest([
    this.router.events.pipe(
      filter((event: Event): event is NavigationEnd => !!event && event instanceof NavigationEnd),
      map((events) => events.url),
    ),
    this.companyDemoStateService.hasDemoMode$,
    this.validatedOperationsStateService.operations$,
    this.notValidatedOperationsStateService.operations$,
    this.needsAttentionOperationsStateService.operations$,
  ]).pipe(
    map(([currentUrl, hasDemoMode, validatedOperations, notValidatedOperations, needsAttentionOperations]) => {
      const matches: RegExpMatchArray | null = currentUrl.match(/[^/]+/g);
      if (hasDemoMode && !!matches?.[1]) {
        if (!this.EXCLUDED_PAGES_FROM_DEMO_MODE.some((url) => url === matches[1]) && matches[1] !== URL.ACCOUNTING) {
          this.shouldShowDemoModeFooterSubject.next(true);
          document.body.classList.add('has-extra-padding-bottom');
        } else if (
          matches[1] === URL.ACCOUNTING &&
          (validatedOperations?.length || notValidatedOperations?.length || needsAttentionOperations?.length)
        ) {
          this.shouldShowDemoModeFooterSubject.next(true);
          document.body.classList.add('has-extra-padding-bottom');
        }
      } else {
        this.shouldShowDemoModeFooterSubject.next(false);
        document.body.classList.remove('has-extra-padding-bottom');
      }
    }),
  );

  async refreshDemoMode(): Promise<void> {
    await this.companyDemoStateService.refreshDemoMode(this.companyStateService.activeCompany.id);
    const hasDemoMode: boolean = await toPromise(this.companyDemoStateService.hasDemoMode$);

    if (!hasDemoMode) {
      this.refreshStatesAfterDeactivation();
    }
  }

  async activateDemoMode(): Promise<void> {
    await this.companyDemoStateService.activateDemoMode(this.companyStateService.activeCompany.id);
    void this.refreshAccounting();
  }

  async deactivateDemoMode(): Promise<void> {
    await this.companyDemoStateService.deactivateDemoMode(this.companyStateService.activeCompany.id);
    this.refreshStatesAfterDeactivation();
  }

  private refreshStatesAfterDeactivation(): void {
    void this.refreshAccounting();
    void this.vehicleStateService.refreshCars(this.companyStateService.activeCompany.id);
    void this.loanStateService.refreshLoans(this.companyStateService.activeCompany.id);
    void this.investmentStateService.refreshInvestments(this.companyStateService.activeCompany.id);
    void this.partnerStateService.refreshPartners(this.companyStateService.activeCompany.id);
  }

  private async refreshAccounting(): Promise<void> {
    await this.synchronizedAccountStateService.refreshSynchronizedAccounts(
      this.companyStateService.activeCompany.id,
      true,
    );
    void this.treasuryStateService.refreshAccountBalances(this.companyStateService.activeCompany.id);
    void this.notValidatedOperationsStateService.refreshOperations(this.companyStateService.activeCompany.id);
    void this.notValidatedOperationsStateService.refreshOperationsCount(this.companyStateService.activeCompany.id);
    void this.validatedOperationsStateService.refreshOperations(this.companyStateService.activeCompany.id);
    void this.needsAttentionOperationsStateService.refreshOperations(this.companyStateService.activeCompany.id);
  }
}
