export const PROVIDER_IMAGE = {
  'bank:bankin': 'bankin.jpg',
  'bank:bi': 'bi.jpg',
  'bank:dougs': 'dougs.jpg',
  'bank:qonto': 'qonto.jpg',
  'bank:shine': 'shine.jpg',
  'bank:stripe': 'stripe.jpg',
  'bank:manual': 'dougs.jpg',
  'bank:csv': 'csv.jpg',
};
