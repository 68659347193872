import { Injectable } from '@angular/core';
import { lastValueFrom, Observable, retry } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Company } from '@dougs/company/dto';
import { Attachment } from '@dougs/core/files';
import { JobApiService } from '@dougs/core/job';
import { JobDto } from '@dougs/core/job-dto';
import { LoggerService } from '@dougs/core/logger';
import { StateService } from '@dougs/core/state';
import {
  Connection,
  CONNECTION_STATUS_ACTION,
  ConnectionAccount,
  SynchronizedAccount,
  TransactionProvider,
} from '@dougs/synchronized-accounts/dto';
import { ConnectionHttpService } from '../http/connection.http';
import { FileHttpService } from '../http/file.http';

import { SynchronizedAccountStateService } from './synchronized-account.state';

interface ConnectionState {
  connections: Connection[];
}

@Injectable({
  providedIn: 'root',
})
export class ConnectionStateService extends StateService<ConnectionState> {
  readonly connections$ = this.select((state) => state.connections);

  constructor(
    private readonly logger: LoggerService,
    private readonly connectionHttpService: ConnectionHttpService,
    private readonly synchronizedAccountStateService: SynchronizedAccountStateService,
    private readonly jobService: JobApiService,
    private readonly fileHttpService: FileHttpService,
  ) {
    super();
  }

  async refreshConnections(companyId: number): Promise<void> {
    try {
      this.setState({
        connections: await lastValueFrom(this.connectionHttpService.getConnections(companyId)),
      });
    } catch (e) {
      this.logger.error(e);
    }
  }

  updateStateConnection(connection: Connection): void {
    this.setState({
      connections: this.state?.connections.map((stateConnection) =>
        stateConnection.id === connection.id ? connection : stateConnection,
      ) || [connection],
    });
  }

  async addConnection(
    company: Company,
    sourceId: string,
    attachments: (Attachment | null)[],
    options?: {
      synchronizedAccount?: SynchronizedAccount;
      credentials?: Record<string, string>;
    },
  ): Promise<Connection | null> {
    try {
      const connectionCreated = await lastValueFrom(
        this.connectionHttpService.createConnection(
          company.id,
          sourceId,
          options?.credentials || {},
          attachments
            .filter((attachment) => attachment)
            .map((attachment) => ({
              type: 'csv',
              fileId: attachment?.fileId,
              fileUuid: attachment?.uuid,
            })),
        ),
      );
      return await lastValueFrom(this.pullConnection(connectionCreated));
    } catch (e) {
      this.logger.error(e);

      return null;
    }
  }

  async mergeConnection(
    company: Company,
    sourceId: string,
    connectionToMerge: Connection,
    options?: {
      credentials?: Record<string, string>;
    },
  ): Promise<Connection | null> {
    try {
      const connectionCreated = await lastValueFrom(
        this.connectionHttpService.mergeConnection(company.id, sourceId, connectionToMerge, options?.credentials || {}),
      );
      return await lastValueFrom(this.pullConnection(connectionCreated));
    } catch (e) {
      this.logger.error(e);

      return null;
    }
  }

  async getConnectionAccounts(connection: Connection): Promise<ConnectionAccount[] | null> {
    try {
      return await lastValueFrom(this.connectionHttpService.getConnectionAccounts(connection));
    } catch (e) {
      this.logger.error(e);
      return null;
    }
  }

  getConnectionById(connectionId: number): Connection | undefined {
    return this.state?.connections.find((connection) => connection.id === connectionId);
  }

  async updateConnection(connection: Connection, credentials = {}): Promise<Connection | null> {
    try {
      const connectionUpdated = await lastValueFrom(
        this.connectionHttpService.updateConnection(connection, credentials),
      );
      return await lastValueFrom(this.pullConnection(connectionUpdated));
    } catch (e) {
      this.logger.error(e);

      return null;
    }
  }

  async getAllConnectionAccounts(companyId: number, sourceId: string): Promise<ConnectionAccount[]> {
    try {
      return await lastValueFrom(this.connectionHttpService.getAllConnectionAccounts(companyId, sourceId));
    } catch (e) {
      this.logger.error(e);
      return [];
    }
  }

  getConnectionsWithAction(connections: Connection[], synchronizedAccounts: SynchronizedAccount[]): Connection[] {
    const connectionIds: number[] = synchronizedAccounts.reduce(
      (acc: number[], cv) => [
        ...acc,
        ...cv.providers.filter((provider) => provider.enabled).map((provider) => provider.connectionId),
      ],
      [],
    );

    return connections.filter(
      (connection) =>
        !connection.deletedAt &&
        ![null, CONNECTION_STATUS_ACTION.WAIT].includes(connection.status.action) &&
        connectionIds.includes(connection.id),
    );
  }

  async synchronizeRemoteConnection(connection: Connection, fromFinalUser = false): Promise<Connection | null> {
    try {
      const synchronizeRemoteConnectionJob = await lastValueFrom(
        this.connectionHttpService.synchronizeRemoteConnection(connection, fromFinalUser),
      );

      if (!synchronizeRemoteConnectionJob) {
        return null;
      }

      await this.waitUntilJobHandled([synchronizeRemoteConnectionJob._id]);

      const synchronizedConnection = await this.getConnection(connection);

      if (!synchronizedConnection) {
        return null;
      }

      this.updateStateConnection(synchronizedConnection);

      return synchronizedConnection;
    } catch (e) {
      this.logger.error(e);
      return null;
    }
  }

  async createConnectionAccounts(
    company: Company,
    accounts: ConnectionAccount[],
    options?: { providerMinDate: string | null; providerMaxDate: string | null },
  ): Promise<void> {
    const synchronizedAccounts: SynchronizedAccount[] = (
      await Promise.all(
        accounts.map((account) =>
          this.synchronizedAccountStateService.createAccount(company, {
            bankStatements: [],
            provider: {
              connectionId: account.connectionId,
              externalId: account.externalId,
              minDate: options?.providerMinDate || null,
              maxDate: options?.providerMaxDate || null,
            },
          }),
        ),
      )
    )?.filter((account: SynchronizedAccount | null) => account !== null) as SynchronizedAccount[];

    await this.waitUntilJobHandled(synchronizedAccounts.map((account) => account?.providers[0].job?._id || ''));
    await Promise.all(
      synchronizedAccounts.map((account) => this.synchronizedAccountStateService.refreshSynchronizedAccount(account)),
    );
  }

  async createProviders(
    company: Company,
    synchronizedAccount: SynchronizedAccount,
    accounts: ConnectionAccount[],
    options?: { providerMinDate: string | null; providerMaxDate: string | null },
  ): Promise<void> {
    const providers: TransactionProvider[] = await Promise.all(
      accounts.map((account) =>
        lastValueFrom(
          this.connectionHttpService.createConnectionProvider(company.id, synchronizedAccount.id, {
            attachments: [],
            connectionId: account.connectionId,
            externalId: account.externalId,
            minDate: options?.providerMinDate || null,
            maxDate: options?.providerMaxDate || null,
          }),
        ),
      ),
    );
    await this.waitUntilJobHandled(providers.map((provider) => provider.job?._id || ''));
    await this.synchronizedAccountStateService.refreshSynchronizedAccount(synchronizedAccount);
  }

  async synchronizeNewProviders(connection: Connection): Promise<TransactionProvider[]> {
    const providers: TransactionProvider[] = await lastValueFrom(
      this.connectionHttpService.synchronizeNewProviders(connection),
    );

    await this.waitUntilJobHandled(providers.map((provider) => provider.job?._id || ''));

    return providers;
  }

  private async waitUntilJobHandled(jobsId: string[]): Promise<void> {
    await Promise.all(
      jobsId.map((jobId) =>
        lastValueFrom(
          this.jobService.handleJob(
            {
              _id: jobId || '',
            } as JobDto,
            5,
          ),
        ),
      ),
    );
  }

  async refreshConnection(connection: Connection): Promise<void> {
    await lastValueFrom(
      this.connectionHttpService.getConnection(connection).pipe(
        tap((connection: Connection) => {
          this.updateStateConnection(connection);
        }),
      ),
    );
  }

  pullConnection(connection: Connection, checkForUpdate = false): Observable<Connection> {
    const updatedAt = connection.updatedAt;

    return this.connectionHttpService.getConnection(connection, true).pipe(
      tap((connection: Connection) => {
        if (
          !(
            connection.status.action !== CONNECTION_STATUS_ACTION.WAIT &&
            (!checkForUpdate || connection.updatedAt !== updatedAt)
          )
        ) {
          throw `connection not handle`;
        }
        this.updateStateConnection(connection);
      }),
      retry({ delay: 2000 }),
    );
  }

  async uploadCSV(file: File): Promise<Attachment | null> {
    try {
      return await lastValueFrom(this.fileHttpService.uploadCSV(file));
    } catch (e) {
      this.logger.error(e);
      return null;
    }
  }

  async getConnection(connection: Connection): Promise<Connection | null> {
    try {
      return await lastValueFrom(this.connectionHttpService.getConnection(connection));
    } catch (e) {
      this.logger.error(e);
      return null;
    }
  }

  async getConnectionProviders(connection: Connection): Promise<TransactionProvider[]> {
    try {
      return await lastValueFrom(this.connectionHttpService.getConnectionProviders(connection));
    } catch (e) {
      this.logger.error(e);
      return [];
    }
  }

  async getConnectionRemoteRedirectUrl(connection: Connection): Promise<{
    remoteRedirectUrl: string;
  }> {
    try {
      return await lastValueFrom(this.connectionHttpService.getConnectionRemoteRedirectUrl(connection));
    } catch (e) {
      this.logger.error(e);
      return { remoteRedirectUrl: '' };
    }
  }

  async resetCredentials(connection: Connection): Promise<void> {
    try {
      if (connection) {
        await lastValueFrom(this.connectionHttpService.resetCredentials(connection));
      }
    } catch (e) {
      this.logger.error(e);
    }
  }
}
