export const SUB_TYPES = {
  checking: 'Compte courant',
  card: 'Compte carte',
  loan: 'Compte de prêt',
  savings: 'Compte épargne',
  market: 'Compte du marché monétaire',
  mortage: 'Crédit hypothécaire',
  deposit: 'Compte de dépôt',
  unknown: 'Inconnu',
  revolvingcredit: 'Crédit renouvelable',
  lifeinsurance: 'Assurance vie',
  consumercredit: 'Crédit à la consommation',
  pea: 'PEA',
  special: 'Compte spécial',
};
