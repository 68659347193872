import { HttpClient, HttpContext, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NGX_LOADING_BAR_IGNORED } from '@ngx-loading-bar/http-client';
import { Observable } from 'rxjs';
import { AppConfig } from '@dougs/core/config';
import { Attachment } from '@dougs/core/files';
import { JobDto } from '@dougs/core/job-dto';
import {
  Connection,
  ConnectionAccount,
  TransactionProvider,
  TransactionProviderPost,
} from '@dougs/synchronized-accounts/dto';

@Injectable({
  providedIn: 'root',
})
export class ConnectionHttpService {
  constructor(private readonly http: HttpClient) {}

  createConnection(
    companyId: number,
    sourceId: string,
    credentials: Record<string, string>,
    attachments: Partial<Attachment>[],
    data?: unknown,
  ): Observable<Connection> {
    return this.http.post<Connection>(`${AppConfig.settings.legacyApiServerUrl}/companies/${companyId}/connections`, {
      sourceId,
      credentials,
      attachments,
      ...(data ? { data } : undefined),
    });
  }

  updateConnection(connection: Connection, credentials: unknown): Observable<Connection> {
    return this.http.post<Connection>(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${connection.companyId}/connections/${connection.id}`,
      {
        credentials,
      },
    );
  }

  getConnections(companyId: number): Observable<Connection[]> {
    return this.http.get<Connection[]>(`${AppConfig.settings.legacyApiServerUrl}/companies/${companyId}/connections`);
  }

  getConnection(connection: Connection, ignoreLoadingBar = false): Observable<Connection> {
    return this.http.get<Connection>(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${connection.companyId}/connections/${connection.id}`,
      {
        context: new HttpContext().set(NGX_LOADING_BAR_IGNORED, ignoreLoadingBar),
      },
    );
  }

  getConnectionRemoteRedirectUrl(connection: Connection): Observable<{
    remoteRedirectUrl: string;
  }> {
    return this.http.get<{
      remoteRedirectUrl: string;
    }>(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${connection.companyId}/connections/${connection.id}/remote-redirect-url?type=${connection.status.redirectType}`,
    );
  }

  getConnectionProviders(connection: Connection): Observable<TransactionProvider[]> {
    return this.http.get<TransactionProvider[]>(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${connection.companyId}/connections/${connection.id}/providers`,
    );
  }

  createConnectionProvider(
    companyId: number,
    accountId: number,
    transactionProvider: TransactionProviderPost,
  ): Observable<TransactionProvider> {
    return this.http.post<TransactionProvider>(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${companyId}/accounts/${accountId}/providers`,
      transactionProvider,
    );
  }

  getConnectionAccounts(connection: Connection): Observable<ConnectionAccount[]> {
    return this.http.get<ConnectionAccount[]>(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${connection.companyId}/connections/${connection.id}/accounts`,
    );
  }

  getAllConnectionAccounts(companyId: number, sourceId: string): Observable<ConnectionAccount[]> {
    return this.http.get<ConnectionAccount[]>(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${companyId}/connections/all/accounts`,
      {
        params: new HttpParams().append('sourceId', sourceId),
      },
    );
  }

  synchronizeRemoteConnection(connection: Connection, fromFinalUser = false): Observable<JobDto> {
    return this.http.post<JobDto>(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${connection.companyId}/connections/${connection.id}/actions/synchronize-remote`,
      {},
      { params: { fromFinalUser } },
    );
  }

  mergeConnection(
    companyId: number,
    sourceId: string,
    connectionToMerge: Connection,
    credentials: Record<string, string>,
  ): Observable<Connection> {
    return this.http.post<Connection>(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${companyId}/connections/${connectionToMerge.id}/actions/merge`,
      {
        sourceId,
        credentials,
      },
    );
  }

  synchronizeNewProviders(connection: Connection): Observable<TransactionProvider[]> {
    return this.http.get<TransactionProvider[]>(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${connection.companyId}/connections/${connection.id}/actions/synchronize-new-providers`,
    );
  }

  resetCredentials(connection: Connection): Observable<Connection> {
    return this.http.get<Connection>(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${connection.companyId}/connections/${connection.id}/actions/reset`,
    );
  }
}
