import { Injectable } from '@angular/core';
import { lastValueFrom, Observable } from 'rxjs';
import { LoggerService } from '@dougs/core/logger';
import { StateService } from '@dougs/core/state';
import { AccountBalance, AccountBalanceTreasury } from '@dougs/synchronized-accounts/dto';
import { SynchronizedAccountBalanceHttpService } from '../http/synchronized-account-balance.http';
import { TreasuryService } from '../services/treasury.service';

interface TreasuryState {
  accountBalanceTreasury: AccountBalanceTreasury;
  hiddenAccountIds: number[];
  accountsBalances: AccountBalance[];
}

@Injectable({
  providedIn: 'root',
})
export class TreasuryStateService extends StateService<TreasuryState> {
  readonly accountBalanceTreasury$: Observable<AccountBalanceTreasury> = this.select(
    (state) => state.accountBalanceTreasury,
  );

  readonly hiddenAccountIds$: Observable<number[]> = this.select((state) => state.hiddenAccountIds);

  constructor(
    private readonly synchronizedAccountBalanceHttpService: SynchronizedAccountBalanceHttpService,
    private readonly logger: LoggerService,
    private readonly treasuryService: TreasuryService,
  ) {
    super();
  }

  async refreshAccountBalances(companyId: number): Promise<void> {
    try {
      const newAccountsBalances: AccountBalance[] = await lastValueFrom(
        this.synchronizedAccountBalanceHttpService.getAccountsBalances(companyId),
      );
      // Init hiddenAccountIds state to empty array & store initial accountsBalances
      this.setState({ accountsBalances: newAccountsBalances, hiddenAccountIds: [] });
      this.updateTreasuryState(newAccountsBalances);
    } catch (e) {
      this.logger.error(e);
    }
  }

  updateAccountBalances(): void {
    const initialAccountsBalances: AccountBalance[] = this.state.accountsBalances;
    const visibleAccountsBalances: AccountBalance[] = initialAccountsBalances.filter(
      (account) => !this.state.hiddenAccountIds.includes(account.accountId),
    );
    this.updateTreasuryState(visibleAccountsBalances);
  }

  updateTreasuryState(newAccountsBalances: AccountBalance[]): void {
    const { totalTreasury, monthlyIncomes, monthlyExpenses }: AccountBalanceTreasury =
      this.treasuryService.extractTreasuryDatasFromAccountBalances(newAccountsBalances);
    this.setState({
      accountBalanceTreasury: {
        totalTreasury,
        monthlyIncomes,
        monthlyExpenses,
      },
    });
  }

  updateHiddenAccountIds(accountId: number): number[] {
    if (this.state.hiddenAccountIds.includes(accountId)) {
      this.setState({
        hiddenAccountIds: this.state.hiddenAccountIds.filter((id) => id !== accountId),
      });
    } else {
      this.setState({
        hiddenAccountIds: [...this.state.hiddenAccountIds, accountId],
      });
    }

    return this.state.hiddenAccountIds;
  }

  setHiddenAccountIds(accountIds: number[]): void {
    this.setState({
      hiddenAccountIds: accountIds,
    });
  }

  resetHiddenAccountIds(): void {
    this.setState({
      hiddenAccountIds: [],
    });
  }
}
