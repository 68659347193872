import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppConfig } from '@dougs/core/config';
import { Source, SourceField } from '@dougs/synchronized-accounts/dto';

@Injectable({
  providedIn: 'root',
})
export class SourceHttpService {
  constructor(private readonly http: HttpClient) {}

  getSources(
    companyId: number,
    options: {
      context: 'initialize' | 'add';
    } = {
      context: 'initialize',
    },
  ): Observable<Source[]> {
    return this.http.get<Source[]>(`${AppConfig.settings.legacyApiServerUrl}/companies/${companyId}/sources`, {
      params: options,
    });
  }

  getSource(companyId: number, sourceId: string): Observable<Source> {
    return this.http.get<Source>(`${AppConfig.settings.legacyApiServerUrl}/companies/${companyId}/sources/${sourceId}`);
  }

  getFields(companyId: number, sourceId: string): Observable<SourceField[]> {
    return this.http.get<SourceField[]>(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${companyId}/sources/${sourceId}/fields`,
    );
  }
}
