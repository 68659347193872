import { Injectable } from '@angular/core';
import { AccountBalance, AccountBalanceTreasury } from '@dougs/synchronized-accounts/dto';

@Injectable({
  providedIn: 'root',
})
export class TreasuryService {
  public extractTreasuryDatasFromAccountBalances(accountsBalances: AccountBalance[]): AccountBalanceTreasury {
    const { totalTreasury, monthlyIncomes, monthlyExpenses } = accountsBalances.reduce(
      (accValues, ab) => {
        accValues.totalTreasury += ab.balance;
        accValues.monthlyIncomes += ab.incomes;
        accValues.monthlyExpenses += ab.expenses;
        return accValues;
      },
      { totalTreasury: 0, monthlyIncomes: 0, monthlyExpenses: 0 },
    );
    return { totalTreasury, monthlyIncomes, monthlyExpenses };
  }
}
