import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppConfig } from '@dougs/core/config';
import { AccountBalance } from '@dougs/synchronized-accounts/dto';

@Injectable({
  providedIn: 'root',
})
export class SynchronizedAccountBalanceHttpService {
  constructor(private readonly http: HttpClient) {}

  getAccountsBalances(companyId: number): Observable<AccountBalance[]> {
    return this.http.get<AccountBalance[]>(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${companyId}/accounts/balances`,
    );
  }
}
