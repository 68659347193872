import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { Company } from '@dougs/company/dto';
import { LoggerService } from '@dougs/core/logger';
import { StateService } from '@dougs/core/state';
import { Source, SourceField } from '@dougs/synchronized-accounts/dto';
import { SourceHttpService } from '../http/source.http';

interface SourceState {
  sources: Source[];
}

@Injectable({
  providedIn: 'root',
})
export class SourceStateService extends StateService<SourceState> {
  readonly sources$ = this.select((state) => state.sources);

  constructor(
    private readonly logger: LoggerService,
    private readonly sourceHttpService: SourceHttpService,
  ) {
    super();
  }

  async refreshSources(
    company: Company,
    options: { context: 'initialize' | 'add' } = { context: 'initialize' },
  ): Promise<void> {
    if (!company) {
      return;
    }
    try {
      this.setState({
        sources: await lastValueFrom(this.sourceHttpService.getSources(company.id, options)),
      });
    } catch (e) {
      this.logger.error(e);
    }
  }

  async getSource(companyId: number, sourceId: string): Promise<Source | null> {
    try {
      return await lastValueFrom(this.sourceHttpService.getSource(companyId, sourceId));
    } catch (e) {
      this.logger.error(e);
      return null;
    }
  }

  async getSourceFields(companyId: number, sourceId: string): Promise<SourceField[]> {
    try {
      return await lastValueFrom(this.sourceHttpService.getFields(companyId, sourceId));
    } catch (e) {
      this.logger.error(e);
      return [];
    }
  }
}
