export const PROVIDER_TYPE = {
  'bank:bankin': 'Bankin',
  'bank:bi': 'Budget Insight',
  'bank:dougs': 'Dougs',
  'bank:qonto': 'Qonto',
  'bank:shine': 'Shine',
  'bank:stripe': 'Stripe',
  'bank:manual': 'Manual',
  'bank:csv': 'CSV',
};
